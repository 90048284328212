import React, { useEffect } from "react";
import './FieldMap.css';

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl-draw/dist/mapbox-gl-draw.css'; // Import the stylesheet


const FielddMap = () => {
    let polygons = [
        {
          id: "0b89070716fb6845055621f8357375dd",
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [
              [
                [13.36114271932891, 43.61879375209267],
                [13.356882890664025, 43.60741420581556],
                [13.376125564979901, 43.613051072636296],
                [13.36114271932891, 43.61879375209267],
              ],
            ],
            type: "Polygon",
          },
        },
        {
          id: "b33f9346e3c5bd3be6e794fb31b24237",
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [
              [
                [13.380826065576343, 43.61400822396422],
                [13.3809729562212, 43.60656331210399],
                [13.405209912421697, 43.612944721548615],
                [13.380826065576343, 43.61400822396422],
              ],
            ],
            type: "Polygon",
          },
        },
      ];
    useEffect(() => {
        mapboxgl.accessToken = "pk.eyJ1IjoiZnJhcmlubzEwMDIiLCJhIjoiY2xnZ3FzN3pmMGVocjNmbzBha3FyM3Y3eSJ9.fvZwDeMpX9_CgmbNtsh5sg";
        const map = new mapboxgl.Map({
            container: "map", // container ID
            style: "mapbox://styles/mapbox/satellite-v9",
            center: [13.37, 43.6],
            zoom: 12,
        });
        let savedPolygons = {
            type: "FeatureCollection",
            features: polygons,
          };
          
          map.on("load", () => {
            map.addSource("polygons", {
              type: "geojson",
              data: savedPolygons,
            });
          
            map.addLayer({
              id: "polygons",
              type: "fill",
              source: "polygons",
              layout: {},
              paint: {
                "fill-color": "#088",
                "fill-opacity": 0.5,
              },
            });
          });
          
          
    }, []);

    return (
        <div className="map-container">
            {/* <img src="./img/map.png" alt="Map Image"></img> */}
        </div>
    );
};

export default FielddMap;
