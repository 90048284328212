import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SideNavBar from "./components/SideNavBar/SideNavBar";
import Dashboard from "./routes/dashboard/Dashboard";
import Polygons from "./routes/polygons/Polygons";
import "./index.css"; // Import your custom CSS

function App() {
	return (
		<Router>
			<div className="app-container">
				<SideNavBar />
				<div className="main-container">
				<Routes>
					<Route path="/" element={<Dashboard />} />
					<Route path="/polygons" element={<Polygons />} />
				</Routes>
				</div>
				
			</div>
		</Router>
	);
}

export default App;
