import React, { useState } from "react";
import "./SideNavBar.css";

const SideNavBar = () => {
  const [isExpanded, setExpendState] = useState(false);
  const menuItems = [
    {
      text: "Dashboard",
      icon: "./icons/dashboard.svg",
      link: "/"
    },
    {
      text: "Manage Fields",
      icon: "./icons/settings.svg",
      link: "/polygons"
    },
  ];
  return (
    <div
      className={
        isExpanded
          ? "side-nav-container"
          : "side-nav-container side-nav-container-NX"
      }
    >
      <div className="nav-upper">
        <div className="nav-heading">
          {isExpanded && (
            <div className="nav-brand">
              <h2>XAQUO</h2>
			        <div style={{marginTop:'28px', color:'var(--green)', fontWeight: 'bold'}}>W</div>
              
            </div>
          
          )}
          
          <button
            className={
              isExpanded ? "hamburger hamburger-in" : "hamburger hamburger-out"
            }
            onClick={() => setExpendState(!isExpanded)}
          >
           
              <span></span>
              <span style={{background:'red', width:'0px'}}></span>
              <span ></span>
          
          </button>
        </div>
        <div className="nav-menu">
          {menuItems.map(({ text, icon, link}) => (
            <a
              className={isExpanded ? "menu-item" : "menu-item menu-item-NX"}
              href={link}
            >
            
              <img className="menu-item-icon" src={icon} alt="" srcset="" />
              {isExpanded && <p className="menu-item-text">{text}</p>}
            </a>
          ))}
        </div>
      </div>
      <div className="nav-footer">
        {isExpanded && (
          <div className="nav-details">
            <img
              className="nav-footer-avatar"
              src="icons/admin-avatar.svg"
              alt=""
              srcset=""
            />
            <div className="nav-footer-info">
              <p className="nav-footer-user-name">F Rinaldi</p>
              <p className="nav-footer-user-position">X admin</p>
            </div>
          </div>
        )}
        <img className="logout-icon" src="icons/logout.svg" alt="" srcset="" />
      </div>
    </div>
  );
};

export default SideNavBar;
