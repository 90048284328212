import React, { useEffect, useState } from "react";
import BaseComponent from "../../components/BaseLayout/BaseLayout";
import CreateFieldButton from "../../components/Buttons/CreateFieldButton";
const Polygons = () => {
  const fields = false;

  const callback = () => {
    alert('clicked');
  }

  const NoFieldsContent = () => {
    return (
      <div style={{ marginTop: "10%", textAlign: "center" }}>
        <div>NO FIELDS FOUND. CREATE A NEW FIELD TO GET STARTED</div>
        <div style={{ marginLeft: "45%", marginTop: "10px" }}>
          <CreateFieldButton></CreateFieldButton>
        </div>
      </div>
    );
  };

  return (
    <div>
      <BaseComponent title="Manage Fields" />
      <div className="dashboard-container">
        <div className="bottom-row">
          <div className="big-box">
          {!fields && <NoFieldsContent />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Polygons;
