import React from "react";
import './BaseLayout.css'
import CreateFieldButton from "../Buttons/CreateFieldButton";
import SimulationButton from "../Buttons/SimulationButton";
import IrrigationOptmisisationButton from "../Buttons/IrrigationOptmisiationButton";

const BaseComponent = (props) => {
    const {
        title, 
        createFieldButton = true,
        simulationButton = true,
        optimisationButton = true
    } = props;
    
    return(
        <div className="tool-bar">
            <div className="title">
                {title} 
            </div>
            <div style={{display:'flex'}}>
                <div className="buttons-div">                    
                    {optimisationButton && <IrrigationOptmisisationButton />}                                
                    {createFieldButton && <SimulationButton />}                                
                </div>
                <div >
                    {simulationButton && <CreateFieldButton />}                                
                </div>
                
            </div>
            
            
        </div>
    );
}

export default BaseComponent;
