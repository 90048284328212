import React, { useEffect, useState } from "react";
import BaseComponent from "../../components/BaseLayout/BaseLayout";
import FielddMap from "../../components/FieldMap/FieldMap";
import WaterSummary from "./WaterSummary";
import "./Dashboard.css";
import WaterIrrigationChart from "../../components/WaterIrrigationChart/WaterIrrigationChart";
import GeneralInfo from "./GeneralInfo";
const Dashboard = () => {
    return (
        <div>
        <BaseComponent title="Field 1" />
        <div className="dashboard-container">
            <div className="top-row">
                <div id="map" className="box box-row-1-big">
                    <FielddMap></FielddMap>
                </div>
                <div className="top-row-small-boxes">
                    <div className="box box-row-1-small">
                        <WaterSummary></WaterSummary>
                    </div>
                    <div className="box box-row-1-small">
                        <GeneralInfo></GeneralInfo>
                    </div>
                </div>                
            </div>
            <div className="bottom-row">
                <div className="big-box">    
                  <WaterIrrigationChart></WaterIrrigationChart>                    
                </div>
            </div>
           
        </div>
        </div>
    );
};

export default Dashboard;
