import React, { useState } from "react";
import { FaLock, FaPlus, FaTractor } from "react-icons/fa";
import Dialog from "../Dialog/Dialog";
import "./style.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

const SimulationButton = (props) => {
    const {
        callback
    } = props;
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  return (
    <div>
      <button data-tooltip-id="my-tooltip-1" className="crop-simulation-button" >
        <FaLock className="plus-icon" ></FaLock>
        Crop Simulation
        <ReactTooltip
        style={{zIndex:1000}}
        id="my-tooltip-1"
        place="bottom"
        content="Coming soon! Utilise simulation models to forecast upcoming crop yields and evaluate the influence of external environmental fluctuations on your crop's growth."
      />
      </button>      
      
    </div>
  );
};

export default SimulationButton;
