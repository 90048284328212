import React, { useEffect, useState } from "react";
import "./Dashboard.css"; // Import your CSS file for styling

const GeneralInfo = () => {
  return (
    <div>
      <div className="box-title">General Info <span style={{color:'#a6a6a6'}} class="smaller-text">(current)</span></div>           
      <div  style={{paddingLeft:'20px'}}>
        <div style={{paddingBottom:'0px', marginBottom:'0px'}} className="box-sub-title">
                Soil Moisture
        </div>
        <div>
            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Temperature at the surface 
                </div>
                <div className="general-info-text-value">
                    20 <span class="smaller-text">°C</span>
                </div>
            </div>
            <div></div>

            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Temp. at the depth of 10cm
                </div>
                <div className="general-info-text-value">
                    20 <span class="smaller-text">°C</span>
                </div>
            </div>

            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Soil moisture
                </div>
                <div className="general-info-text-value">
                    15 <span class="smaller-text">%</span>
                </div>
            </div>                        
        </div>                        
      </div>
      
      <div style={{margingBottom: '0px', marginTop:'12px', paddingRight:'0px'}} className="second-section-box">
      <div >
        <div style={{paddingBottom:'0px', marginBottom:'0px'}} className="box-sub-title">
                Irrigation Status
        </div>
        <div>
            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Total Water To Irrigate
                </div>
                <div className="general-info-text-value">
                    20 <span class="smaller-text">mm/day</span>
                </div>
            </div>
            <div></div>

            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Total water irrigated
                </div>
                <div className="general-info-text-value">
                    15 <span class="smaller-text">mm/day</span>
                </div>                
            </div>
                    
            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Remaining water
                </div>
                <div className="general-info-text-value">
                    5 <span class="smaller-text">mm/day</span>
                </div>
            </div>
        </div>                        
      </div>
     </div> 

     <div style={{marginTop:'12px', paddingRight:'0px'}} className="second-section-box">
      <div >
        <div style={{paddingBottom:'0px', marginBottom:'0px'}} className="box-sub-title">
                Environmental variables
        </div>
        <div>
            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    ET
                </div>
                <div className="general-info-text-value">
                    20 <span class="smaller-text">mm/day</span>
                </div>
            </div>
            <div></div>

            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Humidity at 2m
                </div>
                <div className="general-info-text-value">
                    15 <span class="smaller-text">%</span>
                </div>                
            </div>
                    
            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Solar Radiation
                </div>
                <div className="general-info-text-value">
                    5 <span class="smaller-text">MJ/m²</span>
                </div>
            </div>

            <div className="general-info-single-value-container">
                <div className="general-info-text">
                    Wind Speed
                </div>
                <div className="general-info-text-value">
                    5 <span class="smaller-text">m/s</span>
                </div>
            </div>
        </div>                        
      </div>
     </div> 

    </div>
  );
};

export default GeneralInfo;
