import React, { useEffect, useState } from "react";
import "./dialog.css";

const Dialog = (props) => {
  const { onClose } = props;

  const [isInputActive, setInputActive] = useState(false);
  const [fieldName, setFieldName] = useState()

  const handleInputClick = () => {
    setInputActive(true);
  };

  const handleInputBlur = () => {
    setInputActive(false);
  };

  const handleNameInputOnChange = (e) => {
    let v = e.target.value;    
    setFieldName(v);    
  }


  return (
    <div className="dialog-overlay">
      <div className="dialog-content">
        <h2 className="dialog-title">CREATE A NEW FIELD</h2>
        <h2 className="dialog-sub-title">
          Creating a new field by providing the name and selecting the area of
          the field by drowing the polygon on the map
        </h2>
        <div className='create-field-content-container'>
      <div className={`content-left-panel ${isInputActive ? 'active' : ''}`}>
        <div  className="input-container">
          <input
            type="text"
            onFocus={handleInputClick}
            onChange={handleNameInputOnChange}
            placeholder={!isInputActive ? '' : ''}            
            onBlur={handleInputBlur}            
          />          
          <label>* Name</label>
          <div >
            <div className="create-polygon-title"> 
            Instruction to draw a polygon
            </div>            
            <div className="create-polygon-instruction"> 
            1. Click on the polygon tool to activate draw mode. <br />
            2. Place the pointer on the map and click the location of the first point to start drawing. <br />
            3. Continue clicking at each corner of the shape until you have created the polygon. <br />
            4. Click the first point to stop drawing. <br />
            5. Double-click any point to edit. <br />
            </div>            
          </div>
        </div>
      </div>
      <img src="./img/map.PNG" alt="Map Image" />
    </div>
        <div className="dialog-footer">
          <button className="close-button" onClick={onClose}>
            Close
          </button>
          <button className="close-button" onClick={onClose}>
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dialog;
