import React, { useState } from "react";
import { FaLock, FaPlus, FaTractor } from "react-icons/fa";
import Dialog from "../Dialog/Dialog";
import "./style.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

const IrrigationOptmisisationButton = (props) => {
    const {
        callback
    } = props;
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  return (
    <div>
      <button data-tooltip-id="my-tooltip-2" className="crop-simulation-button" >
        <FaLock className="plus-icon" ></FaLock>
        Irrigation Optimisation
        <ReactTooltip
        style={{zIndex:1000}}
        id="my-tooltip-2"
        place="bottom"
        content="Coming soon! Unlock the power to tailor your irrigation setup to your specific soil, crop, and land, all with the goal of maximising your crop yields while conserving water resources."
      />
      </button>      
      
    </div>
  );
};

export default IrrigationOptmisisationButton;
