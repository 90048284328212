import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Plot from 'react-plotly.js';
import chart from "./irrigation_schedule.json";
import './WaterIrrigationChart.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Bar Chart",
    },
  },
};

const labels = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const data = {
  labels,
  datasets: [
    {
      label: "ET FAO",
      data: [1, 2, 3, 4, 5, 6, 7],
      borderColor: "rgb(9, 150, 31)",
      backgroundColor: "rgba(9, 150, 31, 0.5)",
    },
    {
      label: "XAquoE",
      data: [1, 2, 3, 4, 5, 6, 7],
      borderColor: "rgb(0, 145, 255)",
      backgroundColor: "rgba(0, 145, 255, 0.5)",
    },
  ],
};

const WaterIrrigationChart = () => {
  const plotAsJson = chart;  
  const scale = 1.8
  const [chart_width, set_chart_width] = useState(window.innerWidth/scale);
  const [chart_height, set_chart_height] = useState(window.innerHeight/scale);

  useEffect(() => {
    const handleResize = () => {
      set_chart_width(window.innerWidth/scale);
      set_chart_height(window.innerHeight/scale);
    };

    window.addEventListener('resize', handleResize);

    plotAsJson.layout['width'] = chart_width;
    plotAsJson.layout['height'] = chart_height;

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    
  }, []);

  // Your code to update the layout properties
  useEffect(() => {
    plotAsJson.layout['width'] = chart_width;
    plotAsJson.layout['height'] = chart_height;
    
  }, [chart_width, chart_height]);

  return (
    <>
      <div className="water-irrigation-chart-header">
        <div className="box-title no-border">Irrigation Input Forecast (mm/day) </div>
        <div className="water-irrigation-chart-header-buttons-container">
          <button className="water-irrigation-chart-header-button">            
            Current week 
          </button>
          <button className="water-irrigation-chart-header-button">            
            Next 10 days
          </button>
        </div>
      </div>
      
      <Plot 
        data={plotAsJson.data}  
        layout={plotAsJson.layout} 
      />     

      
    </>
  );
};

export default WaterIrrigationChart;
