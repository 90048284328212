import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import Dialog from "../Dialog/Dialog";
import "./style.css";

const CreateFieldButton = (props) => {
    const {
        callback
    } = props;
  const [showDialog, setShowDialog] = useState(false);

  const handleClick = () => {
    setShowDialog(true);
  };

  return (
    <div>
      <button className="create-field-button" onClick={handleClick}>
        <FaPlus className="plus-icon" />
        Create Field
      </button>
      {showDialog && <Dialog onClose={() => setShowDialog(false)} />}
    </div>
  );
};

export default CreateFieldButton;
