import React, { useEffect, useState } from "react";
import WaterSummaryChart from "./WaterSummaryChart";
import "./Dashboard.css"; // Import your CSS file for styling

const WaterSummary = () => {
  return (
    <div>
      <div className="box-title">Water Saving Summary</div>
      <div class="total-water-performance-container">
      <div class="total-water-saving-performance">↑24% </div>
        <div class="total-water-saving-cash">
        <div className="other-performance-text">2500<span class="smaller-text">mm</span></div>
        <div className="other-performance-text">£42500</div>
        </div>                
      </div>
      <div style={{color: '#a6a6a6', textAlign:'center', fontSize:'0.6rem', marginTop:'5px'}}>
        performance compared to the last months  
      </div>   
      <div className="second-section-box">
        <div className="box-sub-title">
            Water saving trend
        </div>
        <div className="water-summary-chart">
            <WaterSummaryChart></WaterSummaryChart>
        </div>
     </div>   
    </div>
  );
};

export default WaterSummary;
